import axios from "axios";
import { IWarehouse } from "@/types/Warehouse";
import { IInventory } from "@/types/Inventory";
/*
*Inventory service
* Provides UI business logic for commodity inventory
 */
export class InventoryService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IInventory[]> {
    const result: any = await axios.get(`${this.API_URL}/Inventory`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") || "{}",
      },
    });
    return result.data;
  }
  public async create(inventory: IInventory) {
    try {
      const result = await axios.post(`${this.API_URL}/Inventory`, inventory, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        status: error.response.status,
        isSuccess: false,
      };
    }
  }
}
