import axios from "axios";
import { IDonation } from "@/types/Donation";
import { IServiceResponse } from "@/types/ServiceResponse";
/*
 *Donation service
 * Provides UI business logic for donation
 */
export class DonationService {
  API_URL = process.env.VUE_APP_API_URL;

  public async getAll(): Promise<IDonation[]> {
    //console.log("getDonation:", this.API_URL);
    const result: any = await axios.get(`${this.API_URL}/Donation`, {
      headers: {
        Authorization: "Bearer " + localStorage.getItem("token") || "{}",
      },
    });
    return result.data;
  }
  public async create(donation: IDonation): Promise<IServiceResponse> {
    try {
      const result = await axios.post(`${this.API_URL}/Donation`, donation, {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("token") || "{}",
        },
      });
      return result.data;
    } catch (error) {
      return {
        data: null,
        time: new Date(),
        message: `Oops! Something went wrong. ${error.response?.statusText}. Try Again Later.`,
        isSuccess: false,
      };
    }
  }
}
