
import { Component, Vue } from "vue-property-decorator";
import { Defaults } from "@/helpers/Defaults";
import { IDonation } from "@/types/Donation";
import { IDonatedCommodity } from "@/types/DonatedCommodity";
import { IWarehouse } from "@/types/Warehouse";
import {PropType} from "vue";
import {ITruckDriver} from "@/types/TruckDriver";
const InventoryProps = Vue.extend({
  props: {
    commodities: [],
    drivers: [] as PropType<ITruckDriver[]>,
    organisations: [],
    districts: [],
    donation: Object,
    updateInventory: {},
  },
});
@Component({
  name: "UpdateInventory",
  components: {},
})
export default class UpdateInventory extends InventoryProps {
  defaults = new Defaults();
  //donation: IDonation = this.defaults.donation;
  reliefItems: IDonatedCommodity[] = [];
  groupedWarehouses: IWarehouse[] = [];
  save(): void {
    this.donation.districtId = this.donation.district.id;
    this.donation.warehouseId = this.donation.warehouse.id;
    this.donation.donorId = this.donation.donor.id;
    this.donation.donatedCommodities = this.reliefItems;
    console.log(this.donation);
    this.$emit("save:donation", this.donation);
  }
  changeDriver(): void {
    this.$emit("search:driver");
  }
  changeLicence(): void {
    this.$emit("find:licence");
  }
}
