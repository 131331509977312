
import { Component, Vue } from "vue-property-decorator";
import { IUser } from "@/types/User";
import { TransporterService } from "@/services/transporter-service";
import { WarehouseService } from "@/services/warehouse-service";
import { CommodityService } from "@/services/commodity-service";
import { IDispatch } from "@/types/Dispatch";
import { IReceipt } from "@/types/Receipt";
import { ICommodity } from "@/types/Commodity";
import { IWarehouse } from "@/types/Warehouse";
import { ITransporter } from "@/types/Transporter";
import { Defaults } from "@/helpers/Defaults";
import updateInventory from "@/components/UpdateInventoryModal.vue";
import moment from "moment";
import { IInventory } from "@/types/Inventory";
import { InventoryService } from "@/services/inventory-service";
import { Functions } from "@/helpers/Functions";
import { IOrganisation } from "@/types/Organisation";
import { TruckDriverService } from "@/services/truck-driver-service";
import { ITruckDriver } from "@/types/TruckDriver";
import { DonationService } from "@/services/donation-service";
import { IDonation } from "@/types/Donation";
import { ApiService } from "@/services/api-services";
import { IResponseData } from "@/types/ResponseData";
@Component({
  name: "Warehouse",
  components: {
    updateInventory,
  },
})
export default class Warehouse extends Vue {
  userString = localStorage.getItem("user");
  updateInventory = { showOverlay: false };
  donationCountSkeleton = true;
  donationSkeleton = true;
  isAdmin = false;
  defaults = new Defaults();
  functions = new Functions();
  user: IUser = this.defaults.user;
  apiService = new ApiService();
  transporterService = new TransporterService();
  warehouseService = new WarehouseService();
  commodityService = new CommodityService();
  inventoryService = new InventoryService();
  donationService = new DonationService();
  driverService = new TruckDriverService();
  commodityResponseData: IResponseData = this.defaults.responseData;
  transporterResponseData: IResponseData = this.defaults.responseData;
  warehouseResponseData: IResponseData = this.defaults.responseData;
  districtResponseData: IResponseData = this.defaults.responseData;
  loadingPlanResponseData: IResponseData = this.defaults.responseData;
  organisationResponseData: IResponseData = this.defaults.responseData;
  donationResponseData: IResponseData = this.defaults.responseData;
  dispatchResponseData: IResponseData = this.defaults.responseData;
  receiptResponseData: IResponseData = this.defaults.responseData;
  inventoryResponseData: IResponseData = this.defaults.responseData;
  driverResponseData: IResponseData = this.defaults.responseData;
  organisations: IOrganisation[] = [];
  allDispatches: IDispatch[] = [];
  allReceipts: IReceipt[] = [];
  allInventories: IInventory[] = [];
  drivers: ITruckDriver[] = [];
  donation: IDonation = this.defaults.donation;
  groupedWarehouses: IWarehouse[] = [];
  totalPlannedStocks = 0.0;
  totalDispatchedStocks = 0.0;
  newCommodity = false;
  newTransporter = false;
  newWarehouse = false;
  commodityName = "";
  transporterName = "";
  warehouseName = "";
  districtId = 0;
  organisationId = 0;
  isGoodAvailableP = false;
  isGoodDispatchP = false;
  isGoodReceiptP = false;
  isGoodShortfallP = false;
  transporterCurrentPage = 1;
  warehouseCurrentPage = 1;
  perPage = 7;
  selectedDistrict = this.defaults.district;
  selectedWarehouse = this.defaults.warehouse;
  async setTransporters(pageNumber: number): Promise<void> {
    //this.warehousesSkeleton = true;
    let requestParams = this.transporterResponseData.requestParams;
    requestParams.pageNumber = pageNumber;
    const response = await this.apiService.getAll("transporter", requestParams);
    this.transporterResponseData = response.data;
    //this.warehousesSkeleton = false;
  }
  async setWarehouses(pageNumber: number): Promise<void> {
    //this.warehousesSkeleton = true;
    let requestParams = this.warehouseResponseData.requestParams;
    requestParams.pageNumber = pageNumber;
    const response = await this.apiService.getAll("warehouse", requestParams);
    this.warehouseResponseData = response.data;
    //this.warehousesSkeleton = false;
  }
  changeDriver(): void {
    const name = this.donation.driver.name;
    const allDrivers = this.drivers;
    this.donation.driverId = 0;
    this.donation.driver.id = 0;
    if (Array.isArray(allDrivers)) {
      const selectedDriver = allDrivers.find(
        (selectedDriver) =>
          selectedDriver.name.toLowerCase() == name.toLowerCase()
      );
      console.log(selectedDriver);
      this.donation.driver = selectedDriver ?? this.donation.driver;
    }
  }
  changeLicence(): void {
    const licence = this.donation.driver.licence;
    const allDrivers = this.drivers;
    this.donation.driverId = 0;
    this.donation.driver.id = 0;
    if (Array.isArray(allDrivers)) {
      const selectedDriver = allDrivers.find(
        (selectedDriver) =>
          selectedDriver.licence.toLowerCase() == licence.toLowerCase()
      );
      this.donation.driver = selectedDriver ?? this.donation.driver;
    }
  }
  dateRanges = [
    {
      name: "lifetime",
      date: moment("1970-01-01T00:00:00.000").format("YYYY-M-D"),
    },
    { name: "daily", date: moment().format("YYYY-M-D") },
    { name: "weekly", date: moment().startOf("week").format("YYYY-M-D") },
    { name: "monthly", date: moment().startOf("month").format("YYYY-M-D") },
    { name: "yearly", date: moment().startOf("year").format("YYYY-M-D") },
  ];
  selectedDate = this.dateRanges[0];
  rows(items: Array<any>): number {
    return items.length;
  }
  dispatchModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "createDispatchModal" + i,
      showOverlay: false,
    };
  }
  viewDonationModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "viewDonationsModal" + i,
      showOverlay: false,
    };
  }
  deleteDonationModalId(i: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: i,
      name: "deleteDonationModal" + i,
      showOverlay: false,
    };
  }
  editDonationModalId(id: number): {
    id: number;
    name: string;
    showOverlay: boolean;
  } {
    return {
      id: id,
      name: "editDonationModal" + id,
      showOverlay: false,
    };
  }
  get dispatches(): IDispatch[] {
    const dispatches = this.allDispatches;
    const district = this.selectedDistrict;
    const warehouse = this.selectedWarehouse;
    const isWarehouseDefault =
      JSON.stringify(warehouse) === JSON.stringify(this.defaults.warehouse);
    const date = this.selectedDate;
    const isDateLifetime = date.name === this.dateRanges[0].name;
    if (district.name === "" && isWarehouseDefault && isDateLifetime)
      return dispatches;
    let selectedDispatches: IDispatch[] = [];
    for (let dispatch of dispatches) {
      let toFilter = true;
      if (district.name != "")
        toFilter = district.id === dispatch.loadingPlan.warehouse.districtId;
      if (toFilter && !isWarehouseDefault)
        toFilter = warehouse.id === dispatch.loadingPlan.warehouseId;
      if (toFilter && !isDateLifetime)
        toFilter =
          moment(dispatch.loadingPlan.startDate).format("YYYY-M-D") >=
          date.date;
      if (toFilter) selectedDispatches.push(dispatch);
    }
    return selectedDispatches;
  }
  get receipts(): IReceipt[] {
    const receipts = this.allReceipts;
    const district = this.selectedDistrict;
    const warehouse = this.selectedWarehouse;
    const isWarehouseDefault =
      JSON.stringify(warehouse) === JSON.stringify(this.defaults.warehouse);
    const date = this.selectedDate;
    const isDateLifetime = date.name === this.dateRanges[0].name;
    if (district.name === "" && isWarehouseDefault && isDateLifetime)
      return receipts;
    let selectedReceipts: IReceipt[] = [];
    for (let receipt of receipts) {
      let toFilter = true;
      if (district.name != "")
        toFilter =
          district.id === receipt.dispatch.loadingPlan.warehouse.districtId;
      if (toFilter && !isWarehouseDefault)
        toFilter = warehouse.id === receipt.dispatch.loadingPlan.warehouseId;
      if (toFilter && !isDateLifetime)
        toFilter =
          moment(receipt.dispatch.loadingPlan.startDate).format("YYYY-M-D") >=
          date.date;
      if (toFilter) selectedReceipts.push(receipt);
    }
    return selectedReceipts;
  }
  get inventories(): IInventory[] {
    const inventories = this.allInventories;
    const district = this.selectedDistrict;
    const warehouse = this.selectedWarehouse;
    const isWarehouseDefault =
      JSON.stringify(warehouse) === JSON.stringify(this.defaults.warehouse);
    if (district.name === "" && isWarehouseDefault) return inventories;
    let selectedInventory: IInventory[] = [];
    for (let inventory of inventories) {
      let toFilter = true;
      if (district.name != "")
        toFilter = district.id === inventory.warehouse.districtId;
      if (toFilter && !isWarehouseDefault)
        toFilter = warehouse.id === inventory.warehouse.id;
      if (toFilter) selectedInventory.push(inventory);
    }
    return selectedInventory;
  }
  get inventoryPercentage(): number {
    return +(
      this.totalInventoryStocks /
      (2000 * (this.inventories.length || 1))
    ).toFixed(1);
  }
  get totalInventoryStocks(): number {
    const totalInventoryStocks = this.sum(this.inventories);
    return +totalInventoryStocks.toFixed(2);
  }
  get totalReceivedStocks(): number {
    const totalReceivedStocks = this.sum(this.receipts);
    return +(totalReceivedStocks / 20).toFixed(2);
  }
  get receivedIds(): number[] {
    let receivedIds: number[] = [];
    const receipts = this.receipts;
    receipts.filter(function (receipt) {
      if (receipt.dispatchId != null) receivedIds.push(receipt.dispatchId);
    });
    return receivedIds;
  }
  get acknowledgedStocks(): IDispatch[] {
    const dispatches = this.dispatches;
    let receivedIds = this.receivedIds;
    return dispatches.filter(function (dispatch) {
      if (receivedIds.find((element) => element == dispatch.id) != undefined)
        return dispatch;
    });
  }
  get totalAcknowledgedStocks(): number {
    const totalAcknowledgedStocks = this.sum(this.acknowledgedStocks);
    return +(totalAcknowledgedStocks / 20).toFixed(2);
  }
  get lostStock(): number {
    return +(this.totalAcknowledgedStocks - this.totalReceivedStocks).toFixed(
      2
    );
  }
  get plannedPercentage(): number {
    const plannedP =
      this.totalInventoryStocks == 0
        ? 0
        : Math.round(
            (this.totalPlannedStocks / this.totalInventoryStocks) * 100
          );
    this.isGoodAvailableP = plannedP <= 75;
    return plannedP;
  }
  get dispatchPercentage(): number {
    const dispatchP =
      this.totalPlannedStocks == 0
        ? 0
        : Math.round(
            (this.totalDispatchedStocks / this.totalPlannedStocks) * 100
          );
    this.isGoodDispatchP = dispatchP >= 75;
    return dispatchP;
  }
  get receiptPercentage(): number {
    const receiptP =
      this.totalDispatchedStocks == 0
        ? 0
        : Math.round(
            (this.totalReceivedStocks / this.totalDispatchedStocks) * 100
          );
    this.isGoodReceiptP = receiptP >= 75;
    return receiptP;
  }
  get lostPercentage(): number {
    const lostP =
      this.totalDispatchedStocks == 0
        ? 0
        : Math.round((this.lostStock / this.totalDispatchedStocks) * 100);
    this.isGoodShortfallP = lostP < 10;
    return lostP;
  }
  async initialize(): Promise<void> {
    let requestParams = this.defaults.requestParams;
    let response = await this.apiService.getAll("commodity", requestParams);
    if (!response.isSuccess && response.status == 401)
      this.visitRoute("/logout");
    this.commodityResponseData = response.data;
    response = await this.apiService.getAll("transporter", requestParams);
    this.transporterResponseData = response.data;
    requestParams.includes = '["Organisation","District"]';
    response = await this.apiService.getAll("warehouse", requestParams);
    this.warehouseResponseData = response.data;
    requestParams.includes =
      '["Driver", "Warehouse", "Donor", "District", "Recipient", "DonatedCommodities"]';
    response = await this.apiService.getAll("donation", requestParams);
    this.donationResponseData = response.data;
    this.donationCountSkeleton = false;
    this.donationSkeleton = false;
    requestParams.includes = "";
    response = await this.apiService.getAll("loadingPlan", requestParams);
    this.loadingPlanResponseData = response.data;
    response = await this.apiService.getAll("dispatch", requestParams);
    this.dispatchResponseData = response.data;
    response = await this.apiService.getAll("receipt", requestParams);
    this.receiptResponseData = response.data;
    requestParams.includes = '["Warehouses"]';
    requestParams.pageSize = 50;
    response = await this.apiService.getAll("district", requestParams);
    this.districtResponseData = response.data;
    requestParams.includes = "";
    response = await this.apiService.getAll("organisation", requestParams);
    this.organisationResponseData = response.data;
    this.allInventories = await this.inventoryService.getAll();
    this.drivers = await this.driverService.getAll();
  }
  mounted(): void {
    this.user = JSON.parse(localStorage.getItem("user") || "{}");
  }
  async created(): Promise<void> {
    if (this.userString != null) {
      const user = JSON.parse(this.userString);
      const userRoles = user.roles;
      if (userRoles.includes("admin")) {
        this.isAdmin = true;
      }
    }
    await this.initialize();
  }
  async saveCommodity(): Promise<void> {
    let commodity: ICommodity = {
      id: 0,
      name: this.commodityName,
      isArchived: false,
      unit: "",
      type: "",
      commodityInventories: [],
    };
    const result = await this.commodityService.create(commodity);
    let message = "";
    let title = "Creating a new commodity";
    let variant = "danger";
    if (result.isSuccess) {
      this.commodityResponseData.data = this.functions.prepend(
        result.data,
        this.commodityResponseData.data
      );
      this.newCommodity = false;
      message = "New commodity created successfully";
      variant = "success";
    }
    this.makeToast(message, title, variant);
  }
  async saveTransporter(): Promise<void> {
    let transporter: ITransporter = {
      id: 0,
      name: this.transporterName,
      isArchived: false,
    };
    const result = await this.transporterService.create(transporter);
    let message = "";
    let title = "Creating a new Transporter";
    let variant = "danger";
    if (result.isSuccess) {
      this.transporterResponseData.data = this.functions.prepend(
        result.data,
        this.transporterResponseData.data
      );
      this.newTransporter = false;
      message = "New transporter created successfully";
      variant = "success";
    }
    this.makeToast(message, title, variant);
  }
  async saveWarehouse(): Promise<void> {
    let warehouse: IWarehouse = {
      id: 0,
      name: this.warehouseName,
      maxStorage: 0,
      districtId: this.districtId,
      district: {
        id: this.districtId,
        name: "",
        warehouses: [],
      },
      organisationId: this.organisationId,
      organisation: {
        id: this.organisationId,
        name: "",
        contactPerson: "",
        phoneNumber: "",
        warehouses: [],
      },
    };
    const result = await this.warehouseService.create(warehouse);
    let message = "";
    let title = "Creating a new Warehouse";
    let variant = "danger";
    if (result.isSuccess) {
      this.warehouseResponseData.data = this.functions.prepend(
        result.data,
        this.warehouseResponseData.data
      );
      this.newWarehouse = false;
      message = "New warehouse created successfully";
      variant = "success";
    }
    this.makeToast(message, title, variant);
  }
  async saveInventory(inventory: IDonation): Promise<void> {
    this.updateInventory.showOverlay = true;
    const result = await this.donationService.create(inventory);
    let title = "Updating Inventory";
    let variant = "danger";
    if (result.isSuccess) {
      variant = "success";
      this.donation = this.defaults.donation;
    }
    let message = result.message;
    this.updateInventory.showOverlay = false;
    this.$bvModal.hide("updateInventoryModal");
    this.makeToast(message, title, variant);
  }
  sum(items: any[]): number {
    return items.reduce((qty: number, item) => {
      return qty + item.quantity;
    }, 0);
  }
  visitRoute(link: string): void {
    this.$router.push(link);
  }
  makeToast(
    message = "Oh Snap! something went wrong. Please try again later.",
    title = "",
    variant = "danger"
  ): void {
    this.$bvToast.toast(message, {
      title: title,
      autoHideDelay: 5000,
      appendToast: true,
      variant: variant,
    });
  }
}
